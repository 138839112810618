var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"quickSale"},[_c('NavigationBar',{directives:[{name:"show",rawName:"v-show",value:(_vm.is_navbar === 'true'),expression:"is_navbar === 'true'"}],attrs:{"title":_vm.title}}),_c('div',{staticClass:"quickSaleContainer"},[_vm._m(0),_c('div',{staticClass:"quickSaleBody"},[_c('div',{staticClass:"vanTabsTop"},[_c('van-tabs',{attrs:{"line-width":20,"line-height":6,"title-active-color":"#333333","title-inactive-color":"#333333","background":"transparent"},on:{"click":_vm.changeTabs}},_vm._l((_vm.tabList),function(item){return _c('van-tab',{key:item.id,attrs:{"title":item.title}})}),1)],1),_c('div',{staticClass:"vanTabsBody"},_vm._l((_vm.singleList),function(item,index){return _c('div',{key:index,staticClass:"vanCardBodyItem",style:({
            'border-bottom':
              _vm.singleList.length - 1 === index
                ? '0px solid #f5f5f5'
                : '1px solid #f5f5f5',
          })},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"content"},[_vm._v(" "+_vm._s(item.content)+" ")])])}),0)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"quickSaleTop"},[_c('div',{staticClass:"topText"},[_c('span',{staticClass:"text1"},[_vm._v("账号交易有秘籍，"),_c('br'),_vm._v("想迅速出手从发布先人一步")]),_c('span',{staticClass:"text2"},[_vm._v("掌握正确姿势 账号快速出售")])])])
}]

export { render, staticRenderFns }