<template>
  <div>
    <van-nav-bar
      :fixed="true"
      :border="false"
      :placeholder="true"
      :title="title"
      :safe-area-inset-top="true"
      left-arrow
      @click-left="onClickLeft"
    />
  </div>
</template>

<script>
export default {
  name: "NavigationBar",
  props: ["title"],
  data() {
    return {
      isAndroid: "",
      isiOS: "",
    };
  },
  mounted() {
    this.getiOSOrAndroid();
  },
  methods: {
    onClickLeft() {
      if (this.isAndroid) {
        window.jsBridge.goBack();
      } else if (this.isiOS) {
        window.webkit.messageHandlers.ExchangeContinueToBack.postMessage(null);
      }
    },
    // 判断类型
    getiOSOrAndroid() {
      const u = navigator.userAgent;
      //android终端
      const isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1;
      this.isAndroid = isAndroid;
      // this
      //ios终端
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      this.isiOS = isiOS;
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .van-nav-bar .van-icon {
  color: black;
}
</style>
