<template>
  <div class="quickSale">
    <NavigationBar :title="title" v-show="is_navbar === 'true'"></NavigationBar>
    <div class="quickSaleContainer">
      <div class="quickSaleTop">
        <div class="topText">
          <span class="text1"
            >账号交易有秘籍，<br />想迅速出手从发布先人一步</span
          >
          <span class="text2">掌握正确姿势 账号快速出售</span>
        </div>
      </div>
      <div class="quickSaleBody">
        <div class="vanTabsTop">
          <van-tabs
            :line-width="20"
            :line-height="6"
            title-active-color="#333333"
            title-inactive-color="#333333"
            background="transparent"
            @click="changeTabs"
          >
            <van-tab v-for="item in tabList" :title="item.title" :key="item.id">
            </van-tab>
          </van-tabs>
        </div>
        <div class="vanTabsBody">
          <div
            class="vanCardBodyItem"
            v-for="(item, index) in singleList"
            :key="index"
            :style="{
              'border-bottom':
                singleList.length - 1 === index
                  ? '0px solid #f5f5f5'
                  : '1px solid #f5f5f5',
            }"
          >
            <div class="title">{{ item.title }}</div>
            <div class="content">
              {{ item.content }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getConfig } from "@/api/security";
import { NavigationBar } from "@/components";
export default {
  name: "QuickSale",
  data() {
    return {
      is_navbar: "true",
      title: "快速出售小贴士",
      tabList: [],
      singleList: [],
    };
  },
  components: {
    NavigationBar,
  },
  async mounted() {
    if (JSON.stringify(this.$route.query) != "{}") {
      this.is_navbar = this.$route.query.is_navbar || "true";
    }
    let config = {
      key: "quick_selling_tips",
    };
    this.requestGetConfig(config);
  },

  methods: {
    changeTabs(name) {
      let singleTabTitle = this.tabList.find((item) => {
        return item.id === name;
      });
      this.singleList = singleTabTitle.list || [];
    },
    async requestGetConfig(config) {
      try {
        const result = await getConfig(config);
        if (result.code === 20000 || result.status_code === 1000) {
          let content = JSON.parse(result.data.content);
          this.tabList = content;
          this.singleList = content[0].list;
        } else {
          this.$toast.fail(result.message);
        }
      } catch (error) {
        this.$toast.fail("请求失败");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.quickSale {
  width: 100%;
  min-height: 100vh;
  background: #f5f5f5;
  padding-bottom: env(safe-area-inset-bottom);
  .quickSaleContainer {
    .quickSaleTop {
      width: 100%;
      height: 184px;
      background-image: url("~@/static/images/quickSaleBg.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      .topText {
        padding: 28px 0px 0px 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        .text1 {
          display: inline-block;
          width: 195px;
          height: 36px;
          font-size: 13px;
          font-weight: 400;
          color: #ffffff;
          line-height: 18px;
        }
        .text2 {
          display: inline-block;
          width: 222px;
          height: 25px;
          font-size: 18px;
          font-weight: 600;
          color: #ffffff;
          line-height: 25px;
          margin-top: 5px;
          white-space: nowrap;
        }
      }
    }
    .quickSaleBody {
      width: 351px;
      min-height: calc(100vh - 161px);
      background: #ffffff;
      border-radius: 8px;
      margin: 0 auto;
      margin-top: -70px;
      .vanTabsTop {
        // width: 351px;
        height: 44px;
        background: linear-gradient(180deg, #d8f2ff 0%, #ffffff 100%);
        border-radius: 8px 8px 0px 0px;
        display: flex;
        padding-left: 5px;
        box-sizing: border-box;
      }
      .vanTabsBody {
        padding: 0px 14px;
        box-sizing: border-box;
        .vanCardBodyItem {
          padding: 12px 0px 14px 0px;
          border-bottom: 1px solid #f5f5f5;
          .title {
            height: 21px;
            font-size: 15px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
            line-height: 21px;
          }
          .content {
            width: 323px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #888888;
            line-height: 17px;
            margin-top: 3px;
          }
        }
      }
    }
  }
}
::v-deep .van-tab {
  font-size: 16px;
  padding: 0px 9px;
}
::v-deep .van-tabs__wrap {
  border-radius: 8px 8px 0px 0px;
}
::v-deep .van-tabs__line {
  background: linear-gradient(270deg, #6ccdff 0%, #00a8ff 100%);
  bottom: 24px;
}
::v-deep .van-tab--active {
  font-weight: 600;
}
</style>
