<template>
  <div>
    <van-dialog
      v-model="show"
      :showCancelButton="false"
      confirmButtonText="我知道了"
      confirmButtonColor="#00A8FF"
      width="279"
    >
      <div class="dialogContianer">
        <img
          src="../../static/images/successlogo@2x.png"
          alt=""
          v-show="iconType === 1"
          class="iconImg"
        />
        <img
          src="../../static/images/faillogo@2x.png"
          alt=""
          v-show="iconType === 0"
          class="iconImg"
        />
        <div class="title">{{ title }}</div>
        <div class="dialogText">{{ content }}</div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
export default {
  props: ["content", "title", "iconType"],
  name: "SuccessDialog",
  data() {
    return {
      show: false,
    };
  },
};
</script>

<style lang="less" scoped>
.dialogContianer {
  width: 100%;
  height: 100%;
  padding: 20px 25px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  .iconImg {
    width: 38px;
    height: 38px;
  }
  .dialogText {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #777777;
    line-height: 20px;
    text-align: center;
    margin-top: 8px;
  }
  .title {
    height: 25px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #444444;
    line-height: 25px;
    margin-top: 12px;
  }
}
::v-deep .van-dialog__confirm {
  height: 44px;
  border-top: 1px solid #f2f2f2;
}
::v-deep .van-button__text {
  height: 25px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #00a8ff;
  line-height: 25px;
}
::v-deep .van-overlay {
  padding-bottom: constant(safe-area-inset-bottom); ///兼容 IOS<11.2/
  padding-bottom: env(safe-area-inset-bottom); ///兼容 IOS>11.2/
}
</style>
